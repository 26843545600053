.App {
  text-align: left;
}

.App-header {
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
}

.THREE {
  width: 50%;
  height: 95%;
  border: 1px solid white;
}

#leftTHREE {
  position: absolute;
  left: 0px;
}

#rightTHREE {
  position: absolute;
  right: 0px;
}

#footer {
  position: absolute;
  bottom: 0px;
  height: 5%;
  width: 100%;
}

#submitButton {
  width: 25%;
  height: 100%;
  position: absolute;
  left: 0px;
}

#restartButton {
  width: 25%;
  height: 100%;
  position: absolute;
  right: 0px;
}

#customer {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 100;
  padding: 0px;
  margin: 10px;
  color: white;
  font-family: monospace;
}

#placeMarkersLeft {
  height: 2em;
  z-index: 100;
  position: absolute;
  left: 0px;
  margin: 10px;
  top: 3em;
}

#mirrorFeet {
  height: 2em;
  z-index: 100;
  position: absolute;
  left: 0px;
  margin: 10px;
  top: 7em;
  width: 100px;
}

#placeMarkersRight {
  height: 2em;
  z-index: 100;
  position: absolute;
  right: 0px;
  margin: 10px;
  top: 3em;
}
#measureLeft {
  height: 2em;
  z-index: 100;
  position: absolute;
  left: 0px;
  margin: 10px;
  top: 5em;
}

#measureRight {
  height: 2em;
  z-index: 100;
  position: absolute;
  right: 0px;
  margin: 10px;
  top: 5em;
}
#scaleinput{
  position:absolute;
  left:25%;
  z-index:1;
  color:white;
  font-weight: 600;
}

#tooltip{
  position:absolute;
  left:10%;
  bottom:10%;
  height:9em;
  z-index:3;
}